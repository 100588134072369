import { useAuth0 } from '@auth0/auth0-react';
import { isEqual } from 'lodash-es';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { UserRole } from '@relationalai/console-state';

import { RaiUser, ROLES_KEY } from '../../components/auth/raiUser';
import { LoginLayout } from '../../components/layout/Layout';
import { getSettingsURL, getWorksheetsURL } from '../../utils/urlHelper';

function AccountIndex() {
  const router = useRouter();
  const accountId = router.query.accountId as string;
  const { user } = useAuth0<RaiUser>();

  const userRoles = user?.[ROLES_KEY];
  const isSysAdmin = isEqual(userRoles, [UserRole.SYS_ADMIN]);

  useEffect(() => {
    if (router.isReady) {
      if (isSysAdmin) {
        router.replace(getSettingsURL(accountId));
      } else {
        router.replace(getWorksheetsURL(accountId));
      }
    }
  });

  return null;
}

AccountIndex.getLayout = (page: JSX.Element) => (
  <LoginLayout>{page}</LoginLayout>
);

export default AccountIndex;
